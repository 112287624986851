@import 'styles/colors.scss';

.overlayStyle {
  display: flex;
  align-content: center;
  justify-content: center;
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100vw;
  height: 100vh;
  background-color: $darkOverlay;
  // This z-index value is one larger than Material-UI's Sidebar component.
  z-index: 1201;
}

.modalStyle {
  background-color: white;
  border: 1px solid $lightBorder;
  border-radius: 4px;
  align-self: flex-start;
  margin-block-start: 100px;
  padding: 40px;
  width: 600px;
  max-height: 80vh;

  & h1 {
    color: $lightText;
    margin: 0;
    margin-block-end: 10px;
  }

  & h2 {
    color: $lightText;
    margin: 0;
    font-size: 14px;
  }
}
