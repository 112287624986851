/*
SECTION: TEXT
Things that are definitely text.
You can borrow these colors when you know you need good contrast ratios.
*/
$normalText: #050709;
/* lightText should be at least WCAG AA compliant against our WHITE background @ #757575: */
$lightText: #3e3e3e;
/* lightestText here is NOT even "AA" compliant against white, and I'd like to change that some day. */
$lightestText: #757575;
/* TODO: find all `#008CBA` color and replace with this var */
$linkBlue: #1460aa;

/*
YouVersion V3 Design System Colors (sample)
*/
$yvGrey50: #121212;
$yvGrey40: #2B3031;
$yvGrey30: #4F5354;
$yvGrey20: #B9BABB;
// This is the YV Grey 10 with 10% black, as specified in the YV Design System Figma.
// I made it Grey15 because it's between Grey10 and Grey20. This color is great to 
// use on top of grey backgrounds, such as the $yvCanvasLM SCSS background color.
$yvGrey15: #D5D7D7;
$yvGrey10: #EDEFEF;

$yvYellow50: #4C2D08;
$yvYellow40: #975C11;
$yvYellow30: #F09728;
$yvYellow20: #F8CB94;
$yvYellow10: #FEF5EB;

$yvOrange50: #3D1310;
$yvOrange40: #7A2629;
$yvOrange30: #CA3E35;
$yvOrange20: #E59F9A;
$yvOrange10: #FAECEB;

$yvBlue50: #1C2A3B;
$yvBlue40: #29537A;
$yvBlue30: #3D79C2;
$yvBlue20: #9EBCE1;
$yvBlue10: #E7F2FD;

$yvGreen50: #1A3026;
$yvGreen40: #346148;
$yvGreen30: #57A16C;
$yvGreen20: #ABD0B6;
$yvGreen10: #EEF6F0;

$yvRed: #FF3D4D;

$yvTextBlack: $yvGrey50;
$yvTextMuted: #696D6E;
$yvUtilityAlert: $yvOrange30;
$yvUtilityInfo: $yvBlue30;
$yvUtilitySuccess: $yvGreen30;

// Canvas colors for Card UI and surfaces across the app. LM means "light mode" and DM means "dark mode".
$yvCanvasDM: $yvGrey50;
$yvCanvasLM: #FFFFFF;
$yvCanvasSecondaryDM: $yvGrey40;
$yvCanvasSecondaryLM: $yvGrey10;

/*
SECTION: Things that might be text or be behind text.
*/
$buttonBlack: $yvGrey40;
$buttonBlackHover: $yvGrey30;
$errorBg: #ffd6d6;

// Existing classes using this variable will now get the YV Design System updated green color.
$okGreen: $yvUtilitySuccess;

/*
SECTION: UI/layout.
 Things that are decorative or helpful, but not necessarily
 bound by the contrast rules of text.
 THESE COLORS SHOULD NOT BE USED FOR TEXT!
*/

$lightBorder: var(--color-grey-border);
$darkBackdrop: rgba(0, 0, 0, 0.03);
$rowHoverColor: rgba($yvGrey10, .5);
$darkOverlay: rgba(0, 0, 0, 0.5);