@import 'styles/buttons.scss';

.positioner {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  width: 270px;
}

.button {
  & :global(.icon) {
    display: inline-flex;
  }

  @extend .btn;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 270px;
  height: 40px;
}

.toolbarBtn+.toolbarBtn {
  margin-block-start: 15px;
}


.darkButton {
  @extend .button;

  &:any-link {
    color: white;
  }
}

.lightButton {
  @extend .button;
  @extend .btn-white;
}

.activeButton {
  @extend .button;
  @extend .btn;

  background-color: $yvGrey40;
  color: white;
}
