@import 'styles/colors.scss';

.deleteBtn {
  color: $yvGrey30;

  & :hover {
    color: $yvUtilityAlert;
    filter: brightness(0.9);
  }
}

.deleteBtnDisabled {
  color: lightgrey !important;
  cursor: 'auto';
  & :hover {
    color: lightgrey;
  }
}


.greyBtn {
  color: gray !important;

  & :hover {
    color: black !important;
  }
}

.greyBtnDisabled {
  color: lightgrey !important;
  cursor: 'auto';
  & :hover {
    color: lightgrey !important;
  }
}