@import 'styles/colors.scss';

.inlineList {
  list-style: none;
  padding-inline-start: 0;

  & li {
    display: inline;
    padding-inline-end: 20px;

  }
}
