.showPage {
  & .detailSection,
  & .userSection {
    width: 500px;
  }
}


.contentContainer {
  width: 100%;
}

.paneContent {
  position: relative;
}
