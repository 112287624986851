@import 'styles/colors.scss';
@import 'styles/badges.scss';

.card {
  align-self: stretch;
  border: 1px solid $lightBorder;
  border-radius: 4px;
  margin: 25px 0;
  position: relative;
}

.plainSection {
  padding: 1rem;
}

.commentFullView {
  display: flex;
  flex-direction: column;
  width: 450px;

  & .commentList {
    flex: 1;
    overflow: auto;
  }
}

.commentSection {
  border-start-end-radius: 16px;
  border-end-end-radius: 16px;
}

.commentWrap {
  display: flex;
  flex-direction: row;
  margin-block-end: 30px;
  margin-block-start: 20px;
}

.commentInputWrap {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 1rem 0 0 0;

  & > * {
    align-self: flex-start;
  }

  & textarea {
    margin: 0;
  }

  & button {
    align-self: center;
    margin-inline-start: 25px;
  }
}

.avatarWrap {
  & .avatar {
    align-items: center;
    display: flex;
    height: 40px;
    position: relative;
    width: 40px;

    & > img {
      border: 1px solid $yvGrey10;
      border-radius: 50%;
      width: 100%;
      height: auto;
    }
  }
}

.moreButton {
  background: transparent;
  border: none;
  color: $normalText;
  margin-block-end: 25px;
  padding: 0;
  text-decoration: underline;

  & :hover,
  & :active,
  & :focus {
    color: $linkBlue;
  }
}

.saveButton {
  padding: 12px 16px;
}

.commentData {
  flex: 1;
  line-height: 1.785em;
}

.commentText {
  white-space: pre-line;
}

.cardTitleSection {
  display: flex;
  align-items: baseline;
  margin: 0 0 1rem 0;
}

.cardTitle,
.cardTitle a {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  order: 25;
  text-decoration: none;
  text-transform: uppercase;
}

.date {
  order: 75;
  font-size: 11px;

  & strong {
    font-weight: bold;
  }
}

.edit {
  & :any-link {
    text-decoration: none;
  }

  & :global(.icon) {
    font-size: 1.8em;
  }

  display: flex;
  order: 100;
  flex: 1;
  justify-content: flex-end;
}


.commentPrimaryActions{
  display: none;
}

.commentCardContainer{
  &:hover{
    .commentPrimaryActions {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
