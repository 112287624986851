@import 'styles/buttons.scss';
@import 'styles/colors.scss';
@import 'styles/modal.scss';
@import 'styles/forms.scss';
@import 'styles/pagination.scss';
@import 'styles/breakpoints.scss';

$menuOffset: -26px;

.historyList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.historyItem {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 30px 0;
  border-block-end: 1px solid $lightBorder;

  & .historyBadge {
    order: 0;
    margin: 0;
    margin-inline-end: 20px;
  }
}

.plansContainer {
  flex: 1;
  // Adding to Plans container a 20px padding to help it match the other pages, such as the My Org
  // and Groups pages.
  padding: 20px 0 0 0;
  position: relative;
}

.loadingOverlay {
  align-items: center;
  inset-block-end: 0;
  color: white; // This color controls the loading circle color.
  display: flex;
  height: 100vh;
  justify-content: center;
  inset-inline-start: 0;
  max-height: 100vh;
  position: fixed;
  inset-inline-end: 0;
  inset-block-start: 0;
  width: 100vw;
  z-index: 999;
}

.loaderContainer {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.planMenu {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 10px 20px 0 rgba(47, 64, 80, 0.16);
  justify-content: stretch;
  width: auto;
  margin-block-start: 84px;
  position: fixed;
  min-height: 100vh;
  z-index: 1;
}

.planMenuContent {
  position: relative;

  & > .magicBox {
    display: flex;
    align-items: center;
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: -10px;
    inset-inline-end: -10px;
    width: auto;
    height: 70px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    padding: 0;
    padding-inline-start: 40px;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    margin-block-start: 0;
  }

  & > ul {
    margin: 0;
    padding: 0;
    list-style: none;

    & > li {
      display: flex;
      align-items: center;

      &.active:hover {
        background-color: transparent;
        cursor: default;
      }

      &:hover {
        background-color: $rowHoverColor;
        cursor: pointer;
      }

      & div {
        background-color: transparent;
        display: flex;
        align-items: center;
        position: relative;
        padding: 12px 18px;
        height: auto;
        text-transform: uppercase;
        user-select: none;
        width: 100%;

        & :global(.fa-circle) {
          position: relative;
          display: flex;
          align-items: center;
          inset-block-start: 0;
          inset-block-end: 0;
          margin-inline-start: 12px;

          &:global(.draft) {
            color: $yvGrey15;
          }

          &:global(.rejected) {
            color: $yvUtilityAlert;
          }

          &:global(.submitted) {
            color: $yvYellow30;
          }

          &:global(.approved) {
            color: $yvUtilitySuccess;
          }

          &:global(.published) {
            color: $yvUtilityInfo;
          }
        }
      }
    }
  }
}

.planMenuSubHeading {
  text-transform: uppercase;
  padding: 25px;
  padding-inline-start: 30px;
  letter-spacing: 0.08em;
}

.planContent {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.planHeading {
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.planHeadingWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-block-end: 1rem;
}

.statusBadge {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 3px;
  color: white;
  background-color: orange;
}

.addPlan {
  &:any-link {
    color: white;
  }

  & :global(.icon) {
    display: inline-flex;
  }

  @extend .btn;
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  padding: 10px 20px;
  text-transform: uppercase;
  margin: 0 5px;
  margin-inline-start: 20px;
  vertical-align: bottom;
}

.planList {
  width: 100%;
  border-spacing: 0;

  & colgroup {
    & col:nth-child(5) {
      width: 200px;
    }
  }

  & thead th {
    margin: 0;
    border-block-start: 1px solid $lightBorder;
    border-block-end: 1px solid $lightBorder;
    text-align: left;
    color: $lightestText;
    font-weight: normal;
    padding: 20px 0;
  }

  & tbody {
    & tr:hover td {
      background-color: $rowHoverColor;
    }

    & td:first-child {
      & .nameWrapper {
        max-width: 400px;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      & .listImageWrapper {
        display: inline-flex;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 100px;
        vertical-align: middle;

        margin-inline-end: 40px;
        overflow: hidden;

        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        & img {
          max-height: 100%;
          max-width: 100%;
        }
      }

      font-weight: bold;
    }

    & td {
      user-select: none;
      min-width: 110px;
      padding: 20px 20px 20px 0;
      border-block-end: 1px solid $lightBorder;
    }
  }
}

/* specificity override for Card */
div.planBox {
  margin: 1rem 75px 100px 75px;
}

div.planBoxFull {
  display: flex;
  margin: 0;
  flex: 1;
}

.planBoxFull .planSection {
  flex: 1;
  overflow: auto;
}

.headlineArea {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  & .imageWrapper {
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    overflow: hidden;

    & :global(.imageloader) {
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    & img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  & .headline {
    flex: 1;
    padding: 0;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: 1px;
  }
}

.imagePreviews {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  margin-block-start: 40px;
}

.largePreview {
  display: flex;
  flex-direction: column;
  flex: 3;
  align-items: stretch;
  justify-content: flex-end;

  & img {
    max-width: 100%;
    max-height: 700px;
  }
}

.smallPreview {
  @extend .largePreview;
  flex: 1;
}

.infoArea {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;

  & > div > div:first-child {
    font-weight: bold;
  }
}

.descriptionArea {
  & > div:first-child {
    font-weight: bold;
    margin-block-end: 10px;
  }

  & > div:last-child {
    line-height: 25px;
    // white-space: pre-line;
  }
}

.lastSubmittedArea {
  @extend .descriptionArea;
  margin-block-end: 40px;
}

.description {
  // white-space: pre-line;

  & > p {
    margin: 15px 0;
  }

  & > figure > img {
    max-height: 300px;
    max-width: 300px;
  }
}

.newPlanContainer {
  & input[type='checkbox'] {
    margin-block-start: 0;
  }
}

.commaList {
  display: inline-flex;
  flex-wrap: wrap;
}

.commaList span:after {
  content: ',\0000a0';
}

.commaList span:last-child:after {
  content: normal;
}

.dayAdder {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  inset-block-end: -90px;

  & :any-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 15px 35px;
    border-radius: 25px;
    border: 1px solid $lightBorder;
    color: white;
    background-color: black;

    &:hover {
      background-color: $okGreen;
      color: white;
    }
  }

  & :global(.icon-arrows) {
    font-size: 20px;
    color: white;
    margin: 0 5px 0 0;
    display: inline-flex;
  }
}

button.saveButton,
button.saveBtn,
div.saveButton,
div.saveBtn {
  @extend .btnBig;
  width: 175px;
  text-align: center;
}

.approveButton,
.rejectConfirmButton,
.planSubmitButton,
.rejectCancelButton {
  @extend .btnPadded;
}

.rejectCancelButton {
  @extend ._btnCancel;
}

.planSubmitButton {
  background-color: $yvGrey40;
  border: 0;
  font-size: 0.75rem;
  padding: 1rem;
  text-transform: uppercase;
}

.approveButton,
.rejectButton,
.rejectConfirmButton {
  text-transform: uppercase;
  background-color: white;
  color: black;
  margin: 0 5px;
}

.approveButton {
  background-color: $yvGrey40;
  color: white;

  &:hover {
    background-color: $yvGrey30;
    color: white;
  }
}

.rejectConfirmButton:hover {
  background-color: $errorBg;
}

.rejectButton,
.rejectConfirmButton {
  @extend .btnPadded;
  background-color: #f73867;
  color: white;

  &:hover {
    background-color: #f73867;
    opacity: 0.7;
  }
}

button.cancelBtn {
  @extend .btnBig;
  @extend ._btnCancel;
  width: 175px;
}

.narratedAudioHeader {
  align-items: center;
  display: flex;

  & > * {
    margin-inline-end: 4px;
  }
}

.narratedAudioDescription {
  font-size: 12px;
  letter-spacing: 0.3px;
  line-height: 16px;
  margin: 4px 0;
}

.enableBtn {
  & > span {
    color: #2196f3;
    margin-block-start: 4px;
    padding: 0 8px;
  }

  & > span:last-child {
    border: 1px solid rgba(33, 150, 243, 0.5);
  }
}

.imgUploadPlaceholder {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div {
    margin: 5px 0;
  }
}

.largeImgUpload {
  /* Do not assign position here in order to allow for popping out of overflow */
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 285px;
  height: 165px;
  background-color: #fafafa;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  margin-block-start: 15px;

  & > :global(.imageloader) {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & > img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.dayAttachment {
  @extend .largeImgUpload;
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100px;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.planDayVideoField {
  & .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 600px;
  }

  & .dropzone {
    background: #fafafa;
    display: flex;
    margin-block-start: 10px;
    margin-inline-end: 6px;
    height: 48px;
    flex: 1;
    align-items: center;
    justify-content: center;
    border: 1px solid #cecece;
    border-radius: 4px;
  }

  & .textField {
    width: 320px;
  }

  & .textField[disabled] {
    background-color: #fff;
  }

  & .statusWrapper {
    width: 200px;
    display: flex;
  }
}

.mediaPosition {
  margin-block-start: 30px;

  & h4 {
    margin: 0 0 0.5rem 0;
  }

  & p {
    margin: 0;
  }
}

.dayHiddenField {
  display: none;
}

.smallImgUpload {
  @extend .largeImgUpload;
  width: 165px;
  height: 165px;
}

.subText {
  margin-block-start: 7px;
  font-size: 13px;
  color: #888;
}

.tabBar {
  & ul {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  & li {
    display: inline-block;
    font-size: 0.8rem;
    margin: 10px 0.3rem 0 0;
    list-style-type: none;

    &:last-child {
      margin-inline-end: 0;
    }

    &.activeTab {
      background-color: $yvGrey30;
      border-color: $yvGrey30;
      color: #fff;
    }
  }
}

.bookLink {
  display: inline-block;
  color: $linkBlue;
  margin: 10px 0;
  padding: 0;
  cursor: pointer;
}

ul.rangeList {
  display: inline-flex;
  flex-flow: row wrap;
  margin: 1px 0 0 1px;
  padding: 0;

  & > li {
    list-style-type: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align: center;
    color: $linkBlue;
    box-sizing: border-box;
    width: 75px;
    height: 50px;
    margin: -1px 0 0 -1px;
    border-width: 1px;
    border-color: $lightBorder;
    border-style: solid;
  }
}

.submitModal {
  @extend .modalStyle;
  width: 700px;
}

.biblePill {
  @extend .pill;

  background-color: $yvGrey10;
  color: $yvTextBlack;

  &:hover {
    background-color: $yvGrey10;
    color: $yvTextBlack;
  }

  & :global(.fa) {
    color: $yvGrey20;
    cursor: pointer;
    font-size: 1.25rem;
    position: relative;
    inset-inline-end: -5px;
    transition: color 100ms ease-in;

    &:hover {
      color: $yvGrey30;
    }
  }
}

.livePreview {
  & h5 {
    margin-block-end: 0.25em;
  }

  & p {
    margin-block-start: 0;
  }
}

// Temporary CSS override until we update to the YV Design System in our Material-UI app theme.
.muiRoundedButton {
  background-color: $yvGrey10 !important;
  box-shadow: none !important;
  color: $yvTextBlack !important;

  i {
    color: inherit !important;
  }
}

.bibleRefModal {
  background: white;
  border-radius: 4px;
  margin: 2rem;
  max-height: 100vh;
  max-width: 1000px;
  outline: none;
  overflow-y: scroll;
  padding: 1rem 2rem 2rem 2rem;
  width: 100%;
}

.bibleRefModalHeader {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  transition: height 1s ease-in;
}

.selectedReference {
  color: $yvTextMuted;
  margin-block-start: 0;
}

.caption {
  color: $yvTextMuted;
  margin: 0;
  margin-block-end: 1rem;
}

.inactiveTab {
  border-color: $yvGrey10;
  color: $yvGrey15;
  cursor: default;
  pointer-events: none;
}

.manageCategoriesContainer {
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  width: 100%;
  padding: 15px 20px;
}

.categoryPillsContainer {
  flex: 1;
  max-height: 25vh;
  overflow: scroll;
}

.manageCategoriesHeaderContainer {
  display: flex;
  flex-direction: column;
  margin-block-end: 1vh;
}

.categoryPillsContainer::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.categoryPillsContainer::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.categoryPillsContainer::-webkit-scrollbar-thumb {
  background: $yvGrey15;
  border-radius: 500px;
}

/* Handle on hover */
.categoryPillsContainer::-webkit-scrollbar-thumb:hover {
  background: $yvGrey20;
}

@media only screen and (max-width: $medium) {
  .actionButtonsContainer {
    display: flex;
    justify-content: center;
    margin-block-end: 2rem;
    width: 100%;

    & > button {
      flex-grow: 1;
    }
  }

  .bibleRefModal {
    margin: 1rem;
  }

  .bibleRefModalHeader {
    flex-direction: column;
  }
}

.planImagesTitle {
  margin-inline: 0px;
  margin-block: 0px;
}
