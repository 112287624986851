@import "styles/colors.scss";

.paginationBarWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.paginationInfo {
  color: $lightText;
}

.monthPagination {
  flex: 1;
  display: flex;
  justify-content: center;

  &>button {
    text-decoration: none;
    margin: 0 1rem;
    color: $lightestText;
  }
}

.pagination {
  user-select: none;
  margin: 20px 0;
  padding: 0;

  &>li {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-block;

    & a {
      display: inline-block;
      list-style-type: none;
      margin: 0;
      padding: 5px;
      cursor: pointer;
      color: $lightestText;

      &:hover {
        color: $linkBlue;
      }
    }

    &.activePage a {
      color: black;
    }

    &.paginationBreak a {
      border-width: 0;
      cursor: auto;

      &:hover {
        background-color: transparent;
        color: black;
      }
    }
  }
}

.sortableHeading {
  cursor: pointer;
}