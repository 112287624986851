@import 'styles/colors.scss';

.transferListWrapper {
  margin: 25px 0;

  .card {
    align-self: stretch;
    border: 1px solid $lightBorder;
    border-radius: 4px;
    margin: 25px 0;
    position: relative;

    .heading {
      margin: 16px;
      margin-block-end: 0px;
    }
  }

  .customList {
    width: 286px;
    height: 370px;
    overflow: auto;
  }

  .iconButton {
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 48px;
    margin: 8px 16px;
  }
}
