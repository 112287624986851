@import 'styles/colors.scss';

$baseFontFamily: 'Roboto', sans-serif;

html,
body,
:global(#root) {
  display: flex;
  flex-direction: column;
  font-family: $baseFontFamily;
  min-height: 100vh;
}

strong {
  font-weight: bold;
}

/* Prevent scrolling when modal is open */

:global(.ReactModal__Body--open) {
  overflow: hidden;
}

:global(#root) {
  flex: 1;
}

html {
  /* Force scrollbar to prevent layout shifting */
  /* Disabling due to weirdness with modals */
  /* overflow-y: scroll; */

  box-sizing: border-box;
  color: $normalText;
  font-size: 16px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1 {
  color: #111111;
}

p {
  font-size: 1rem;
  line-height: 25px;
}

.base {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}

:a {
  color: $yvGrey50;

  &:hover {
    color: $yvUtilityInfo;
  }
}

/* Forms */
input,
textarea {
  border: 1px solid $yvGrey15;
  border-radius: 4px;
  display: block;
  margin: 10px 0;
  padding: 15px;
  width: 100%;

  &:disabled {
    background-color: #d4d4d4;
    cursor: not-allowed;
  }
}

input[type='checkbox'],
input[type='radio'] {
  display: inline;
  width: auto;
  margin-inline-end: 10px;
}

/* Override for react-select */
:global(.Select-control) {
  width: 100%;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 10px 0;
}

textarea {
  height: 125px;
}

label {
  font-weight: bold;
  font-size: 0.75rem;
}
