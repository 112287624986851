@import 'styles/buttons.scss';
@import 'styles/colors.scss';
@import 'styles/pagination.scss';

.filtersForm {
  margin-block-end: 25px;
}

.listImageWrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  vertical-align: middle;
  border-radius: 20px;
  margin-inline-end: 40px;
  overflow: hidden;

  &>div {
    display: flex;
    max-height: 100%;
    max-width: 100%;

    &>img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.badge {
  display: inline-block;
  margin: 0;
}

.pendingBadge {
  @extend .badge;
  background-color: #FAA127;
}

.pendingBtn {
  @extend .btn;
  text-transform: uppercase;
  font-size: 10px;
  padding: 6px;
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.fullWidth{
  width: 100%;
}
