@import 'styles/pagination.scss';

.tableBody {
  & td:first-of-type {
    padding-inline-start: 20px;
  }

  & p {
    margin-block-end: 0;
  }
}
