@import 'styles/colors.scss';

.btn {
  background-color: $yvGrey40;
  border: 0 solid $yvGrey40;
  border-radius: 45px;
  color: #fff;
  font-size: 1rem;
  letter-spacing: 1.2px;
  padding: 12px;
  text-decoration: none;
  text-transform: uppercase;

  &:any-link {
    color: #fff;
  }

  &:any-link:hover,
  &:hover {
    background-color: $yvGrey30;
    color: white;
    cursor: pointer;
  }

  &:disabled {
    background-color: $yvGrey10;
    color: $yvTextMuted;
    cursor: default;

    &:hover {
      opacity: 0.7;
    }
  }
}

.btnDefaultLM {
  @extend .btn;

  background-color: $yvGrey10;
  border: 0 solid $yvGrey10;
  color: $yvTextBlack;

  &:any-link:hover,
  &:hover {
    background-color: $yvGrey10;
    color: $yvTextBlack;
    cursor: pointer;
    filter: brightness(0.9);
  }
}

.btnSecondaryLM {
  @extend .btn;

  background-color: $yvGrey15;
  border: 0 solid $yvGrey15;
  color: $yvTextBlack;

  &:any-link:hover,
  &:hover {
    background-color: $yvGrey15;
    color: $yvTextBlack;
    cursor: pointer;
    filter: brightness(0.9);
  }
}

.btnActive {
  border: 0 solid $buttonBlack;
  background-color: $okGreen;
  border-radius: 45px;
  color: #fff;
  padding: 5px;
  font-size: 14px;
  letter-spacing: 1.2px;
  text-decoration: none;
  text-transform: uppercase;

  &:any-link {
    color: #fff;
  }

  &:any-link:hover,
  &:hover {
    cursor: pointer;
    background-color: #faa127;
    color: white;
  }

  &:disabled {
    background-color: $yvGrey10;
    color: $yvTextMuted;
    cursor: default;

    &:hover {
      opacity: 0.7;
    }
  }
}

.btnBig {
  display: inline-block;
  padding: 16px;
  width: 100%;
  @extend .btn;
}

._btnCancel {
  background-color: $yvGrey10;
  color: $yvGrey40;

  &:hover {
    background-color: $yvGrey10;
    color: $yvGrey40;
    filter: brightness(0.9);
  }
}

.btnSecondary {
  @extend .btn;
  @extend ._btnCancel;
}

.cancelBtn,
.btnCancel {
  @extend .btnBig;
  @extend ._btnCancel;
}

.btnPadded {
  @extend .btn;
  padding: 13px 35px;
}

.btnPill {
  @extend .btn;
  align-items: center;
  background-color: $yvGrey10;
  color: $yvTextBlack;
  display: inline-flex;
  font-size: 11px;
  height: 2rem;
  margin-inline-end: 8px;
  padding: 0 12px;

  &:hover {
    background-color: $yvGrey10;
    color: $yvTextBlack;
    filter: brightness(0.9);
  }
}

.btnPillSecondary {
  @extend .btnPill;
  background-color: white;
  border: 1px solid $yvGrey15;
  color: $yvTextBlack;

  &:hover {
    background-color: white;
    color: $yvTextBlack;
    filter: brightness(0.9);
  }

  &:disabled {
    border: 1px solid transparent;
  }
}

.btnPillActive {
  @extend .btnActive;
  display: inline-block;
  padding: 6px 10px;
  margin-inline-end: 20px;
  font-size: 11px;
}

.btnPillReject {
  @extend .btnPillSecondary;
  transition: all 100ms ease-in;

  &:hover {
    background-color: $yvUtilityAlert;
    border: 1px solid $yvUtilityAlert;
    color: white;
    filter: brightness(1.0);
  }
}

.btnPillAccept {
  @extend .btnPillSecondary;
  transition: all 100ms ease-in;

  &:hover {
    background-color: $yvUtilitySuccess;
    border: 1px solid $yvUtilitySuccess;
    color: white;
    filter: brightness(1.0);
  }
}

.pill {
  @extend .btnPill;
  align-items: center;
  display: inline-flex;
  font-size: 0.8125rem;
  height: 32px;
  margin-block-end: 10px;
  text-transform: none;

  &:hover {
    cursor: auto;
  }
}

.bluePill {
  @extend .btnPill;
  background-color: #1460aa;
  border: 0 solid #1460aa;
  border-radius: 45px;
  color: #fff;
  font-size: 11px;
  letter-spacing: 0.2px;
  padding: 2px 6px;
  text-transform: none;

  &:hover {
    background-color: #1460aa;
    color: white;
    cursor: auto;
  }

  &:disabled {
    background-color: #a0a0a0;
    color: #c5c5c5;
    cursor: auto;
  }
}

.pillNoHover {
  @extend .pill;

  &:hover {
    background-color: black;
    color: #fff
  }
}

.btn-white {
  @extend .btn;
  background-color: white;
  border: 1px solid $yvGrey10;
  color: $yvTextBlack;
  padding: 10px;

  &:any-link {
    color: $yvTextBlack;
  }

  &:any-link:hover,
  &:hover,
  &.authNavActive {
    background-color: $yvGrey10;
    color: $yvTextBlack;
  }
}
