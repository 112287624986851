@import 'styles/colors.scss';

.contentContainer {
  width: 100%;
}

.paneContent {
  position: relative;
}

.filters {
  margin-block-start: 1rem;
  display: flex;

  & .filter {
    padding: .5rem;
    flex-grow: 1;
  }
}

.table {
  width: 100%;
  border: none;
  border-spacing: 0;
  margin-block-start: 1rem;

  & th {
    background-color: $rowHoverColor;
    text-align: left;
    padding: 1rem;
  }

  & td {
    padding: 1rem;
  }
}

.totalHeading th {
  background-color: #fff !important;
  font-weight: normal;
}

.repColumn {
  width: 30%;
}

.partnerColumn {
  width: 30%;
}

.createdColumn {
  width: 25%;
}

.plansColumn {
  width: 15%;
  text-align: right !important;
}

.staffRow {
  & td {
    padding-block-start: 3rem;
    border-block-end: 1px solid $lightBorder;
  }
}
