@import 'styles/buttons.scss';
@import 'styles/colors.scss';
@import 'styles/modal.scss';
@import 'styles/forms.scss';
@import 'styles/pagination.scss';
@import './UserTable.module.scss';

.orgModal {
  @extend .modalStyle;
  width: 500px;
  max-height: 90vh;
}

.orgWrapper {
  & h4 {
    margin-block-end: 5px;
  }

  & address {
    white-space: pre-line;
  }
}

.partner {
  margin: 20px 0;
}

/* TODO: This is duped. Refactor! */
.imageWrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  vertical-align: middle;
  border-radius: 6px;
  overflow: hidden;

  & > div:first-child {
    display: flex;
  }
}

.toolbarButton {
  @extend .btn-white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 270px;
  margin-block-end: 15px;
}

.toolbarButtonActive {
  @extend .toolbarButton;

  background-color: $yvGrey40;
  color: white;

  &:hover {
    background-color: $yvGrey30;
    color: white;
  }
}

.toolbarButtonSecondary {
  @extend .toolbarButton;

  background-color: $yvGrey10;
  color: $yvTextBlack;

  &:hover {
    background-color: $yvGrey10;
    color: $yvTextBlack;
    filter: brightness(0.9);
  }
}

.orgForm {
  width: 450px;
}

.fileUploaderWrapper {
  /* Do not assign position here in order to allow for popping out of overflow */
  display: inline-flex;
  border-radius: 10px;
  overflow: hidden;
  width: 175px;
  height: 175px;
}

.partnershipUploadWrapper {
  @extend .btnPadded;
  margin: 10px 0;
}

.mt1 {
  margin-block-start: 1rem;
}
