@import 'styles/colors.scss';

.badge {
  align-self: baseline;
  background-color: $yvUtilitySuccess;
  border-radius: 16px;
  color: white;
  font-size: 0.66rem;
  letter-spacing: 1px;
  margin: 0 20px;
  order: 50;
  padding: 6px 10px;
  text-transform: uppercase;

  &:global(.draft) {
    background-color: $yvGrey10;
    color: $yvTextBlack;
  }

  &:global(.rejected),
  &:global(.error) {
    background-color: $yvUtilityAlert;
  }

  &:global(.submitted),
  &:global(.in_review),
  &:global(.pending) {
    background-color: $yvYellow30;
  }

  &:global(.approved),
  &:global(.done) {
    background-color: $yvUtilitySuccess;
  }

  &:global(.published),
  &:global(.live) {
    background-color: $yvUtilityInfo;
  }
}