@import 'styles/colors.scss';
@import 'styles/buttons.scss';

.imageWrapper {
  align-items: center;
  display: inline-flex;
  flex: 1;
  justify-content: center;
  position: relative;
}

.fileAttachmentWrapper {
  display: inline-block;
}

.error {
  position: relative;
  z-index: 0;
  background-color: $yvOrange10;
  padding: 10px;
  padding-block-start: 14px;
  margin-block-start: calc(-10px + -4px);
  margin-block-end: 10px;
  border-end-end-radius: 4px;
  border-end-start-radius: 4px;
  font-weight: normal;
  font-size: 12px;
  background-image: $yvOrange10;
  color: $yvOrange40;
}

:global(.react-datepicker__input-container) {
  width: 100%;
}

.tabBar {
  & ul {
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  & li {
    padding: 0 0 5px 0;
    margin: 0 30px 5px 0;
    list-style-type: none;
    display: inline-block;
    cursor: pointer;
    user-select: none;
    border-block-end: 1px solid transparent;

    &:last-child {
      margin-inline-end: 0;
    }

    &.activeTab {
      text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
      border-block-end: 1px solid black;
    }
  }
}

.rawHtmlWrapper {
  position: relative;
  border: 1px dashed gray;
  padding: 10px;
  padding-block-start: 30px;
  max-height: 100px;
  overflow: auto;

  & *,
  & div {
    padding: 0;
    margin: 0;
    font-family: monospace;
  }
}

.rawHtmlWrapper:before {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  content: 'HTML Code';
  font-size: 11px;
  background-color: #cecece;
  padding: 5px;
}
