@import 'styles/buttons.scss';

$greyBorder: #dddddd;

.groupsContainer {
  margin: 25px 0;
  width: 50%;
}

.groupsList {
  border-block-start: 1px solid $greyBorder;
  border-block-end: 1px solid $greyBorder;
  margin-block-end: 2rem;
  padding: 0;

  & li {
    height: 45px;
    list-style: none;
    border-block-end: 1px solid $greyBorder;
    display: flex;
    align-items: center;

    & .groupName {
      flex-grow: 4;
    }

    & .groupDelete {
      background-color: transparent;
      flex-grow: 0;
      font-size: 12px;
    }
  }

  & li:last-child {
    border-block-end: none;
  }
}

.select {
  width: 70%;
  z-index: 5;
}

.button {
  @extend .btnSecondary;
  margin-inline-start: 1rem;
}

.addGroupSection {
  align-items: center;
  display: flex;
}
