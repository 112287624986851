/*

  Usage:
  Currently used in:
   * Organizations/Organizations.module.scss
   * Groups/Pages/Show/Users/List


*/
@import 'styles/colors.scss';

.userList {
  width: 100%;
  border-spacing: 0;

  & .deleteBtn {
    color: red;

    & :hover {
      color: darkred;
    }

  }

  & .deleteBtnDisabled {
    color: lightgrey;
    cursor: 'auto';

    & :hover {
      color: lightgrey;
    }
  }

  & colgroup {
    & col:nth-child(5) {
      width: 200px;
    }
  }

  & thead th {
    margin: 0;

    border-block-end: 1px solid $lightBorder;
    text-align: left;
    color: $lightestText;
    font-weight: normal;
    padding: 20px 0;
  }

  & tbody {
    & tr:hover td {
      background-color: $rowHoverColor;
    }

    & tr {
      cursor: pointer;
    }

    & tr.editing {
      cursor: auto;
    }

    & td {
      user-select: none;
      padding: 20px 5px;
      border-block-end: 1px solid $lightBorder;

      & .userName {
        font-weight: bold;

        & .userStatus {
          display: block;
          font-weight: normal;
        }
      }

      & .updateStatus {
        color: #9ACD32;
        display: inline-block;
        height: 24px;
        vertical-align: text-bottom;
        margin-inline-start: 5px;

        @keyframes fadein {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }
      }
    }

    & td:first-child {
      padding-inline-start: 10px;
    }

    & td:last-child {
      padding-inline-end: 10px;
    }

    & td.photo {
      width: 5%;

      & .listImageWrapper {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        vertical-align: middle;
        border-radius: 20px;
        margin-inline-end: 20px;
        overflow: hidden;

        &>div {
          display: flex;
          max-height: 100%;
          max-width: 100%;

          &>img {
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }

    & td.name {
      width: 25%;
    }

    & td.role {
      width: 15%;
    }

    & td.status {
      width: 10%;
    }

    & td.email {
      width: 25%;
    }

    & td.controls {
      width: 10%;
      text-align: right;
    }
  }
}
