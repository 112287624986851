@import 'styles/buttons.scss';
@import 'styles/colors.scss';

.paneContent {
  padding: 0;
}

.formContent {
  width: 600px;

  &>div>div>input,
  &>div>div> :global(.fieldError),
  &>div :global(.react-datepicker__input-container),
  &>div :global(.Select) {
    width: 370px;
    max-width: 100%;
  }

  &>div {
    margin-block-start: 2rem;
  }

  &>button[type='submit'],
  &>div[type='submit'] {
    margin: 10px 0;
    width: 175px;
    text-align: center;
  }

  & fieldset {
    border: none;
    padding: 0;
  }

  & fieldset>label {
    display: block;
  }

  &:last-child {
    margin-block-start: 25px;
  }
}

.formContentFull {
  @extend .formContent;

  &>div>input,
  &>div>div>input,
  &>div>div :global(.fieldError) {
    width: 600px;
  }
}

.formContentCompact {
  &>div {
    margin-block-start: 15px;
  }
}

.table {
  width: 100%;
  border-spacing: 0;

  & thead th {
    margin: 0;

    border-block-end: 1px solid $lightBorder;
    text-align: left;
    color: $lightestText;
    font-weight: normal;
    padding: 20px 0;
  }

  & tbody {

    & tr:hover td,
    & :global(.activeRow) td {
      background-color: $darkBackdrop;
    }

    & td {
      padding: 20px 0;
      border-block-end: 1px solid $yvGrey10;
    }
  }
}

.loadingButton {
  @extend .btnSecondaryLM;
  display: inline-block;
}

.photoAttachWrapper {
  align-items: center;
  background-color: $rowHoverColor;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;

  & :global(.imageloader) img {
    max-width: 100%;
    max-height: 100%;
  }
}

.uploaderWrapper {
  position: absolute;
  inset-block-start: -15px;
  inset-inline-end: 25px;
}

.uploader {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: black;
  color: white;
  font-size: 10px;
}

.photoAttachWrapper:hover .uploader {
  box-shadow: 0px 0px 4px #555;
  background-color: white;
  color: black;
}

.uploaderActive {
  @extend .uploader;

  /* couldn't figure out how to get postcss to @extend nested :hover */
  box-shadow: 0px 0px 4px #555;
  background-color: white;
  color: black;
}
