@import 'styles/colors.scss';

.errorWrapper {
  border: 1px solid $lightBorder;
  border-radius: 4px;
  background-color: $errorBg;
  padding: 15px;
  margin: 10px 0;
}

label>span {
  font-weight: 100;
}

:global(.rdw-editor-wrapper) {
  margin-block-start: 10px;
}
