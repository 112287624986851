@import '../../../styles/colors.scss';

.dayBox {
  position: relative;

  // Borrowed from the Plans.module.scss file for dynamic coloring to display the Plan day's status.
  & :global(.fa-circle) {
    font-size: 0.875rem;
    position: absolute;
    inset-inline-end: 2px;
    inset-block-start: 2px;

    &:global(.approved) {
      color: $yvUtilitySuccess;
    }

    &:global(.draft) {
      color: $yvGrey15;
    }

    &:global(.published) {
      color: $yvUtilityInfo;
    }

    &:global(.rejected) {
      color: $yvUtilityAlert;
    }

    &:global(.submitted) {
      color: $yvYellow30;
    }
  }

  &:hover {
    color: $yvTextBlack;
  }
}

.dayBoxSelected {
  border-color: $yvTextBlack !important;
  border-width: 3px !important;
  color: $yvTextBlack !important;
}
